import React, { useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { isObjEmpty } from "../../../shared/utils/functions";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import "./FindPostCode.css";

const FindPostCode = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [cityArr, setCityArr] = useState([]);
  const [city, setCityCode] = useState({
    cityName: "",
  });

  const [postError, setPostError] = useState("");

  const submitPostCode = async (e) => {
    e.preventDefault();
    if (!isObjEmpty(city)) {
      setPostError("Please fill the city!");
      return;
    }
    try {
      const response = await sendRequest(
        `collection_update/search_city`,
        "POST",
        {
          city: city.cityName,
        }
      );
      setCityArr(response.data);
      setCityCode({
        cityName: "",
      });
      console.log(cityArr);
      setPostError("");
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <section className="container mb-2">
      <div className="row pt-5 pb-3 justify-content-center">
        {/* <p className="text-center code-reject">
          "Unfortunately we don't accept direct orders from Scotland at the
          moment, please contact +44 (0) 1582 561 029 if you wish to ship from
          Scotland."
        </p> */}
        {postError && (
          <div className="col-md-12 text-center">
            <p className="text-danger">{postError}</p>
          </div>
        )}
        <div className="postcode-input-wrapper">
          <input
            type="text"
            placeholder="Enter City"
            className="input-group post-input"
            value={city.cityName}
            onChange={(e) =>
              setCityCode({
                cityName: e.target.value,
              })
            }
          />

          <button className="btn find-btn" onClick={submitPostCode}>
            {isLoading ? (
              <LoadingSpinner color="white" small />
            ) : (
              <BiSearchAlt size={20} />
            )}
          </button>
        </div>
      </div>
      {cityArr &&
        cityArr.map((items, index) => {
          return (
            items && (
              <React.Fragment>
                {items.address === "Edinburgh" ||
                items.address === "Glasgow" ||
                items.address === "Dundee" ||
                items.address === "Aberdeen" ||
                items.address === "Inverness" ||
                items.address === "Perth" ||
                items.address === "Stirling" ? (
                  <p className="text-center code-reject">
                    "Unfortunately we don't accept direct orders from Scotland
                    at the moment, please contact +44 (0) 1582 561 029 if you
                    wish to ship from Scotland."
                  </p>
                ) : (
                  <p key={index} className="text-center post-text">
                    {index === 0 && (
                      <React.Fragment>
                        We, are coming in your area <br />
                        <span className="font-weight-bold">
                          {items.address}
                        </span>{" "}
                        on
                        {/* <span className="text-secondary font-weight-bold">
                      {" "}
                      {items.postcode}
                    </span>{" "} */}
                        {/* <br /> */}
                      </React.Fragment>
                    )}{" "}
                    &nbsp;
                    <span className="text-danger font-weight-bold">
                      {items.timestamp?.split("T")[0]}
                      {` ${items.timestamp?.split("T")[1]?.split(":")[0]}:${
                        items.timestamp?.split("T")[1]?.split(":")[1]
                      } `}
                    </span>
                  </p>
                )}
              </React.Fragment>
            )
          );
        })}
    </section>
  );
};

export default FindPostCode;

import * as React from "react";
import Input from "./Input";
import { validate, VALIDATOR_REQUIRE } from "../../shared/utils/validators";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from "@devexpress/dx-react-chart-bootstrap4";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import { Animation } from "@devexpress/dx-react-chart";
import "./MainGraph.css";
import { useState } from "react/cjs/react.development";

const MainGraph = (props) => {
  const [data, setData] = React.useState([]);
  // const [graphDate, setGraphDate] = useState({ startDate: "", endDate: "" });
  // const [graphDateErr, setGraphDateErr] = useState({
  //   startDateErr: "",
  //   endDateErr: "",
  // });
  // const [dateFieldErr, setDateFieldErr] = useState("");
  // const maxDate = new Date().toISOString().split("T")[0];

  // const handleChange = (e) => {
  //   if (e.target.id === "startDate") {
  //     if (validate(e.target.value, [VALIDATOR_REQUIRE()]))
  //       setGraphDateErr({ ...graphDateErr, startDateErr: "" });
  //     else
  //       setGraphDateErr({
  //         ...graphDateErr,
  //         startDateErr: "Start date is required!",
  //       });
  //     setGraphDate({ ...graphDate, startDate: e.target.value });
  //   }

  //   if (e.target.id === "endDate") {
  //     if (validate(e.target.value, [VALIDATOR_REQUIRE()]))
  //       setGraphDateErr({ ...graphDateErr, endDateErr: "" });
  //     else
  //       setGraphDateErr({
  //         ...graphDateErr,
  //         endDateErr: "End date is required!",
  //       });
  //     setGraphDate({ ...graphDate, endDate: e.target.value });
  //   }
  // };

  React.useEffect(() => {
    let values = [];
    for (let i = 0; i < props.data.length; i++) {
      values.push({ date: props.data[i].date, shipments: props.data[i].count });
      if (i === props.data.length - 1) setData(values);
    }
  }, [props.data]);

  return (
    data.length !== 0 && (
      <div className="bg-white rounded shadow mt-3 px-3 py-3 mb-5">
        <div className="d-flex justify-content-between align-items-end pb-3">
          <p className="graph_heading">Total Shipments Statistics</p>
          {/* <div className="d-flex align-items-end">
            <div className="d-flex align-items-end pr-3">
              <p className="date_heading">From</p> &nbsp; &nbsp;
              <Input
                element="input"
                type="date"
                id="startDate"
                onChange={handleChange}
                value={graphDate.startDate}
                error={graphDateErr.startDateErr}
                max={maxDate}
              />
            </div>

            <div className="d-flex align-items-end pr-3">
              <p className="date_heading">To</p> &nbsp; &nbsp;
              <Input
                element="input"
                type="date"
                id="endDate"
                onChange={handleChange}
                value={graphDate.endDate}
                error={graphDateErr.endDateErr}
                max={maxDate}
              />
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  if (graphDate.startDate !== "" && graphDate.endDate !== "") {
                    props.filterDateValue(
                      graphDate.startDate,
                      graphDate.endDate
                    );
                    setDateFieldErr("");
                  } else setDateFieldErr("Start & End dates are required!");
                  setGraphDate({ startDate: "", endDate: "" });
                }}
              >
                Search
              </button>
            </div>
          </div> */}
        </div>
        {/* {dateFieldErr && (
          <p className="text-danger text-right py-1" style={{ margin: "0px" }}>
            {dateFieldErr}
          </p>
        )} */}

        <div className="d-flex justify-content-between">
          <div className="order_head d-flex align-items-center">
            <p className="m-0 graph_heading">Orders</p>
          </div>
          <div style={{width: "95%"}}>
          <Chart data={data}>
            <ArgumentAxis />
            <ValueAxis max={7} />

            <BarSeries
              valueField="shipments"
              argumentField="date"
              color="#0799c2"
            />
            {/* <Title text="Total Shipments Statistics" /> */}
            <Animation />
          </Chart>
          </div>
        </div>
        <p className="text-center m-0 graph_heading">Dates</p>
      </div>
    )
  );
};

export default MainGraph;

import React, { useContext, useEffect, useState } from "react";
import { FaTruckLoading } from "react-icons/fa";

import { validate, VALIDATOR_REQUIRE } from "../../../shared/utils/validators";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import InnerHeadingFrame from "../Navigation/InnerHeadingFrame";
import CollectionUpdateItem from "../customer/CollectionUpdateItem";
import Modal from "../../../shared/components/Modal";
import Input from "../../../shared/components/Input";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import { AuthContext } from "../../../shared/context/auth-context";
import axios from "axios";
import "./CollectionUpdate.css";
import { AiOutlineConsoleSql } from "react-icons/ai";

const CollectionUpdate = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [collectionUpdate, setCollectionUpdate] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [collectionUpdateModal, setCollectionUpdateModal] = useState(false);
  const [addCityModal, setAddCityModal] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [inputError, setInputError] = useState({
    // address: "",
    // postcode: "",
    address: "",
    timestamp: "",
  });
  const [collectionUpdateState, setCollectionUpdateState] = useState({
    // address: "",
    // postcode: "",
    address: "",
    timestamp: "",
  });
  const [cityErr, setCityErr] = useState("");
  const [city, setCity] = useState("");
  const [postArr, setPostArr] = useState([]);
  const [postcodeApiErr, setPostcodeApiErr] = useState("");
  const [postcodeSearchLoading, setPostcodeSearchLoading] = useState(false);

  const searchBarInputHandler = (event) => {
    setSearchBarInput(event.target.value);
    if (event.target.value === "") getCollectionUpdateApi();
    setCollectionUpdate(
      collectionUpdate.filter((data) =>
        data?.address?.toString()?.includes(event.target.value)
      )
    );
  };

  const collectionUpdateModalHandler = () => {
    setCollectionUpdateModal((prevMode) => !prevMode);
    setPostcodeApiErr("");
    setCollectionUpdateState({
      address: "",
      postcode: "",
      timestamp: "",
    });
  };

  const addCityModalHandler = () => {
    setAddCityModal((prevMode) => !prevMode);
    setCity("");
    setCityErr("");
  };

  const updateCollectionModal = (id, timestamp, address) => {
    const date = new Date(timestamp);
    console.log(address);
    console.log(timestamp);
    console.log(id);
    setCollectionUpdateState({
      id,
      address,
      timestamp: `${date.toISOString().split(":")[0]}:${
        date.toISOString().split(":")[1]
      }`,
    });
    setCollectionUpdateModal((prevMode) => !prevMode);
  };

  const setPostcodeAndAddressHandler = async (address, url) => {
    const response = await axios(
      `${process.env.REACT_APP_POSTCODE_API}${url}/?api-key=by3U2OzkAUmqhNyZSu7J5Q33106`
    );
    let postcode = response.data.postcode;
    setCollectionUpdateState({ ...collectionUpdateState, address, postcode });
    setPostArr([]);
  };

  const updateCollectionUpdateApi = async () => {
    if (collectionUpdateState.address === "") {
      setInputError({ ...inputError, address: "City is Required." });
      return;
    }
    setInputError({ ...inputError, address: "" });
    if (collectionUpdateState.timestamp === "") {
      setInputError({ ...inputError, timestamp: "Select Time." });
      return;
    }
    setInputError({ ...inputError, timestamp: "" });
    console.log(collectionUpdateState);
    try {
      const response = await sendRequest(
        "collection_update/update_collection_update",
        "PATCH",
        collectionUpdateState
      );
      console.log(response);
      collectionUpdateModalHandler();
      getCollectionUpdateApi();
      setCollectionUpdateState({
        address: "",
        timestamp: "",
      });
    } catch (err) {}
  };

  const handleChange = (e) => {
    if (e.target.id === "address") {
      setCollectionUpdateState({
        ...collectionUpdateState,
        address: e.target.value,
      });
      if (validate(e.target.value, [VALIDATOR_REQUIRE()])) {
        setInputError({ ...inputError, address: "" });
      } else {
        setInputError({ ...inputError, address: "City is Required." });
      }
    }

    if (e.target.id === "time") {
      setCollectionUpdateState({
        ...collectionUpdateState,
        timestamp: e.target.value,
      });
      if (validate(e.target.value, [VALIDATOR_REQUIRE()])) {
        setInputError({ ...inputError, timestamp: "" });
      } else {
        setInputError({ ...inputError, timestamp: "Time is Required." });
      }
    }
  };

  const setState = (event) => {
    if (event.target.id === "postcode") {
      setCollectionUpdateState({
        ...collectionUpdateState,
        postcode: event.target.value,
      });
      if (validate(event.target.value, [VALIDATOR_REQUIRE()])) {
        setInputError({ ...inputError, postcode: "" });
      } else {
        setInputError({ ...inputError, postcode: "Postcode is Required." });
      }
    } else if (event.target.id === "address") {
      setCollectionUpdateState({
        ...collectionUpdateState,
        address: event.target.value,
      });
      if (validate(event.target.value, [VALIDATOR_REQUIRE()])) {
        setPostcodeApiErr("");
      } else {
        setPostcodeApiErr("Address is Required");
      }
      console.log(event.target.value);
    } else {
      setCollectionUpdateState({
        ...collectionUpdateState,
        timestamp: event.target.value,
      });
      if (validate(event.target.value, [VALIDATOR_REQUIRE()])) {
        setInputError({ ...inputError, timestamp: "" });
      } else {
        setInputError({ ...inputError, timestamp: "Select Time." });
      }
    }
    console.log(collectionUpdateState);
  };

  const getCollectionUpdateApi = async () => {
    try {
      const response = await sendRequest(
        "collection_update/get_active_collection_update"
      );
      console.log(response.data.collectionUpdate);
      setCollectionUpdate(response.data.collectionUpdate);
      // console.log(response.data.distinctPostcode);
    } catch (err) {}
  };

  const createCollectionUpdateApi = async () => {
    if (collectionUpdateState.address === "") {
      setInputError({ ...inputError, address: "City is Required." });
      return;
    }
    setInputError({ ...inputError, address: "" });
    if (collectionUpdateState.timestamp === "") {
      setInputError({ ...inputError, timestamp: "Select Time." });
      return;
    }
    setInputError({ ...inputError, timestamp: "" });
    console.log(collectionUpdateState);
    try {
      const response = await sendRequest(
        "collection_update/create_collection_update",
        "POST",
        collectionUpdateState
      );
      console.log(response);
      collectionUpdateModalHandler();
      getCollectionUpdateApi();
      setCollectionUpdateState({
        address: "",
        timestamp: "",
      });
    } catch (err) {}
  };

  const createCityApi = async () => {
    if (city === "") {
      setCityErr("City is required!");
      return;
    }
    setCityErr("");
    try {
      const response = await sendRequest("address/add_city", "POST", {
        city: city,
      });
      console.log(response);
      setAllCities([...allCities, response?.data])
      setCity("");
      addCityModalHandler();
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllCityApi = async () => {
    try {
      const response = await sendRequest("address/city_list");
      console.log(response.data.cityList);
      setAllCities(response?.data?.cityList);
      // console.log(allCities[0]?.address);
    } catch (err) {
      console.log(err.message);
    }
  };
  // const getPostAddressApi = async () => {
  //   setPostcodeApiErr("");
  //   if (!collectionUpdateState.address)
  //     return setPostcodeApiErr("Please Enter Address");
  //   setPostcodeSearchLoading(true);
  //   try {
  //     const response = await axios(
  //       // `${process.env.REACT_APP_POSTCODE_API}search?text=${collectionUpdateState.address}&apiKey=9659fa42d6a842c6ab0d8911a4dea90b`
  //       `${process.env.REACT_APP_ADDRESS_API}${collectionUpdateState.address}/?api-key=by3U2OzkAUmqhNyZSu7J5Q33106`
  //     );
  //     console.log(response.data.suggestions);
  //     setPostArr(response.data.suggestions);
  //     console.log(postArr);
  //     setPostcodeSearchLoading(false);
  //   } catch (err) {
  //     console.log(err.message);
  //     setPostcodeSearchLoading(false);
  //   }
  // };

  useEffect(() => {
    getAllCityApi();
    getCollectionUpdateApi();
  }, []);

  return (
    <React.Fragment>
      <InnerHeadingFrame
        heading="Collection Update"
        icon={<FaTruckLoading size={35} className="mt-2 mr-1" />}
        loading={isLoading}
      >
        <div className="d-flex justify-content-end px-2 mt-3">
          <input
            type="text"
            className="list-search-bar shadow"
            placeholder="Search City"
            value={searchBarInput}
            onChange={searchBarInputHandler}
          />
          {(auth.role === "employee" || auth.role === "admin") && (
            <button
              className="btn info-gradient-btn shadow"
              onClick={collectionUpdateModalHandler}
            >
              Add New
            </button>
          )}{" "}
          &nbsp; &nbsp;
          <button
            className="btn info-gradient-btn shadow"
            onClick={addCityModalHandler}
          >
            Add City
          </button>
        </div>
        <div className="row px-2">
          {collectionUpdate?.map((data, index) => (
            <CollectionUpdateItem
              key={index}
              data={data}
              openUpdateModal={updateCollectionModal}
            />
          ))}
        </div>
      </InnerHeadingFrame>

      <Modal
        show={collectionUpdateModal}
        onCancel={collectionUpdateModalHandler}
        footerContent={
          <React.Fragment>
            <button
              className="btn btn-light"
              onClick={collectionUpdateModalHandler}
            >
              Close
            </button>
            {collectionUpdateState.id ? (
              <button
                className="btn btn-primary ml-2"
                onClick={updateCollectionUpdateApi}
              >
                {isLoading ? <LoadingSpinner xsmall color="white" /> : "Update"}
              </button>
            ) : (
              <button
                className="btn btn-primary ml-2"
                onClick={createCollectionUpdateApi}
              >
                {isLoading ? <LoadingSpinner xsmall color="white" /> : "Add"}
              </button>
            )}
          </React.Fragment>
        }
      >
        <div className="px-3 pt-2">
          {/* <div className="postcode-search-section">
            <Input
              element="input"
              type="text"
              id="address"
              label="Address"
              value={collectionUpdateState.address}
              onChange={setState}
              error={inputError.postcode}
              autoComplete="off"
              error={postcodeApiErr}
            />
            <button className="btn btn-primary" onClick={getPostAddressApi}>
              {postcodeSearchLoading ? (
                <LoadingSpinner xsmall color="white" />
              ) : (
                "Search"
              )}
            </button>
            {postArr.length !== 0 && (
              <div className="postcode-list">
                {postArr?.map((items, index) => {
                  console.log(items.address);
                  return (
                    items.address && (
                      <button
                        key={index}
                        onClick={() =>
                          setPostcodeAndAddressHandler(items.address, items.url)
                        }
                      >
                        <span className="font-weight-bold">
                          Postcode: {items.address}
                        </span>
                         <span>
                          <small>{items.properties.formatted}</small>
                        </span> 
                      </button>
                    )
                  );
                })}
              </div>
            )}
          </div> */
          /* {postcodeApiErr && (
            <p className="text-danger px-2">{postcodeApiErr}</p>
          )} */}

          <div className="postcode-search-section">
            <select
              className="custom-select-field"
              id="address"
              value={collectionUpdateState.address}
              onChange={handleChange}
            >
              <option>Select City</option>
              {allCities?.map((item, index) => (
                <option key={index} value={item.city}>
                  {item.city}
                </option>
              ))}
            </select>
          </div>
          {/* <Input
            element="input"
            type="text"
            id="postcode"
            label="Postcode"
            value={collectionUpdateState.postcode}
            onChange={setState}
            readOnly
            error={inputError.postcode}
          /> */}
          <Input
            element="input"
            type="datetime-local"
            id="time"
            value={collectionUpdateState.timestamp}
            onChange={handleChange}
            error={inputError.timestamp}
          />
        </div>
      </Modal>

      <Modal
        show={addCityModal}
        onCancel={addCityModalHandler}
        footerContent={
          <React.Fragment>
            <button className="btn btn-light" onClick={addCityModalHandler}>
              Close
            </button>

            <button
              className="btn btn-primary ml-2"
              onClick={createCityApi}
            >
              {isLoading ? <LoadingSpinner xsmall color="white" /> : "Add"}
            </button>
          </React.Fragment>
        }
      >
        <div className="px-3 py-4">
          <Input
            label="City Name"
            type="text"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
          <p className="text-danger text-center m-0 pt-2">{cityErr}</p>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default CollectionUpdate;
